<!-- eslint-disable max-len -->
<template>
  <div class="c-phone-verify">
    <c-go-back backroute="mobile.index" @click="closeCode" />
    <c-frame v-if="show" bacroute="mobile.payment" :url="frameUrl" :show.sync="show" />
    <n-loader :loading="$var('load')" />
    <div v-if="needCheck && step === 0" class="content">
      <div class="text">
        <h1>
          <t name="1.4.1" />
        </h1>
        <p>
          <t name="1.4.2" />
        </p>
      </div>
      <c-code-input :received="code" :code.sync="code" />
      <div class="lower-text">
        <p :class="{danger: !!$var('message')}">{{ $var('message') || $app.secure.clean($n.t('1.4.3')) }}</p>
        <p v-if="timer > 0">
          <t name="1.4.7" />
          {{ timer }}
        </p>
        <p v-else @click="sendCode">
          <t name="1.4.4" />
        </p>
      </div>
      <div class="button-place">
        <n-button v-if="!hideButton" type="submit" class="user-default-button" :disabled="isDisabled" @click="close()">
          <t name="1.4.5" />
        </n-button>
      </div>
    </div>
    <div v-if="showWoopaySuccess">
      <main>
        <div class="payment-info">
          <div class="payment-success">
            <div class="success-icon">
              <svg width="320" height="200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M154.987 36.8076C172.84 56.4912 194.393 50.0911 211.349 45.3906C240.245 37.3798 265.708 101.752 222.507 124.926C173.374 151.282 148.693 202.459 113.216 160.975C77.7401 119.49 130.669 109.191 102.059 72.2839C73.4486 35.3771 132.671 12.203 154.987 36.8076Z" fill="#912EDE" />
                <path d="M105.544 85.131C131.234 78.337 135.469 56.2556 139.097 39.0388C145.28 9.6972 214.149 16.7081 214.848 65.7273C215.642 121.477 249.724 166.937 196.568 179.348C143.413 191.759 158.621 140.027 112.689 148.447C66.757 156.868 73.4306 93.6235 105.544 85.131Z" fill="#DCB6FA" fill-opacity="0.44" />
                <rect x="129" y="65.6246" width="72" height="72" rx="36" fill="white" />
                <rect x="145" y="81.6246" width="40" height="40" rx="20" fill="#912EDE" />
                <path d="M155 103L157 101L162 106L173 95L175 97L162 110L155 103Z" fill="white" />
              </svg>
            </div>
            <div class="payment-title">
              <h2 data-lang="title"><t name="5.6.7" /></h2>
              <p class="gray-text"><t name="5.7.4" /></p>
            </div>
            <div class="payment-content">
              <div class="head-row">
                <div class="data-key" data-lang="order_number"><t name="5.7.5" />&nbsp;</div>
                <div class="data-value">
                  <span>№{{ woopayOperationId }}</span>
                </div>
              </div>

              <div class="line" />

              <div class="data-row">
                <div class="data-key" data-lang="amount"><t name="5.6.9" />:</div>
                <div class="data-value">
                  <span>{{ woopayAmount }} ₸</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="amount">Комиссия:</div>
                <div class="data-value">
                  <span>{{ woopayCommission }} ₸</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="amount"><t name="5.7.6" />:</div>
                <div class="data-value">
                  <span>{{ +woopayAmount + +woopayCommission }} ₸</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="date"><t name="5.7.0" />:</div>
                <div class="data-value">
                  <span>{{ woopayDateTime }}</span>
                </div>
              </div>
            </div>
            <button class="close-payment-button" @click="redirect">
              <t name="1.11.12" />
            </button>
          </div>
        </div>
      </main>
    </div>
    <div v-if="showBerekeSuccess">
      <main>
        <div class="payment-info">
          <div class="payment-success">
            <div class="success-icon">
              <svg width="340" height="260" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M154.987 36.8076C172.84 56.4912 194.393 50.0911 211.349 45.3906C240.245 37.3798 265.708 101.752 222.507 124.926C173.374 151.282 148.693 202.459 113.216 160.975C77.7401 119.49 130.669 109.191 102.059 72.2839C73.4486 35.3771 132.671 12.203 154.987 36.8076Z" fill="#912EDE" />
                <path d="M105.544 85.131C131.234 78.337 135.469 56.2556 139.097 39.0388C145.28 9.6972 214.149 16.7081 214.848 65.7273C215.642 121.477 249.724 166.937 196.568 179.348C143.413 191.759 158.621 140.027 112.689 148.447C66.757 156.868 73.4306 93.6235 105.544 85.131Z" fill="#DCB6FA" fill-opacity="0.44" />
                <rect x="129" y="65.6246" width="72" height="72" rx="36" fill="white" />
                <rect x="145" y="81.6246" width="40" height="40" rx="20" fill="#912EDE" />
                <path d="M155 103L157 101L162 106L173 95L175 97L162 110L155 103Z" fill="white" />
              </svg>
            </div>
            <div class="payment-title">
              <h2 data-lang="title"><t name="5.6.7" /></h2>
            </div>
            <div class="payment-content">
              <div class="head-row">
                <div class="data-key" data-lang="order_number">Платеж&nbsp;</div>
                <div class="data-value">
                  <span>№{{ berekeOperationId }}</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="amount"><t name="5.6.9" />:</div>
                <div class="data-value">
                  <span>{{ berekeAmount }} ₸</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="amount">Комиссия:</div>
                <div class="data-value">
                  <span>{{ berekeCommission }} ₸</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="amount"><t name="5.7.6" />:</div>
                <div class="data-value">
                  <span>{{ +berekeAmount + +berekeCommission }} ₸</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-key" data-lang="date"><t name="5.7.0" />:</div>
                <div class="data-value">
                  <span>{{ berekeDateTime }}</span>
                </div>
              </div>
            </div>

            <button class="close-payment-button" @click="redirect">
              <t name="1.11.12" />
            </button>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import imgPhoneVerify from 'assets/phone-verify.svg'

export default {
  name: 'CPhoneVerify',
  props: {
    amount: {
      required: true,
    },
    needCheck: {
      default: null,
      required: false,
    },
    toCardsId: {
      required: false,
    },
    cardNumber: {
      required: false,
    },
    cardHolder: {
      default: '',
      required: false,
    },
    mask: {
      required: false,
    },
    cardIdentifier: {
      required: false,
    },
    verificationType: {
      required: false,
    },
    otpData: {
      default: {},
      required: false,
    },
    documentId: {
      default: 0,
      required: false,
    }
  },
  data() {
    return {
      showWoopaySuccess: false,
      showBerekeSuccess: false,
      code: '',
      step: 0,
      isDisabled: true,
      payData: [],
      user: $app.auth.user(),
      showAfterPay: false,
      sum: 0,
      isSuccess: false,
      hideButton: false,
      show: false,
      frameUrl: '',
      interval: setInterval(this.decTimer, 1000),
      timer: 30,
      woopayAmount: '',
      woopayCommission: '',
      woopayDateTime: '',
      woopayOperationId: '',
      berekeAmount: '',
      berekeCommission: '',
      berekeDateTime: '',
      berekeOperationId: '',
      imgPhoneVerify,
    }
  },

  watch: {
    code() {
      if (this.code.length === 4) {
        this.isDisabled = false
      }
    },
    show() {
      if (!this.show) {
        $app.store.action('app.updateUser').finally(() => {
          this.$router.push({ name: 'mobile.index', })
        })
      }
    },
  },
  created() {
    if (!this.needCheck) {
      this.doTransaction()
    }
  },
  beforeDestroy() {
    this.removeListener()
  },
  methods: {
    setCode() {
      document.addEventListener('onSMSArrive', (args) => {
        this.code = args.message.substr(0, 4)
      })
    },
    removeListener() {
      document.removeEventListener('onSMSArrive', (args) => {
      })
    },
    listenSms() {
      this.setCode()
      cordova.plugins.SMSRetriever.startWatch((r) => {
      }, (e) => {
      })
    },
    closeCode() {
      this.$emit('update:show', false)
    },
    decTimer() {
      this.timer--
      if (this.timer <= 0) {
        clearInterval(this.interval)
      }
    },
    sendCode() {
      this.$var('load', true)
   
      const data = {
        iin: this.user.iin,
        phone: this.user.phone,
        documentId: this.documentId 
      }

      switch (this.verificationType) {
      case 'otp':
        $api.auth.sendCodeOtp(data)
          .then(() => {
            if (Capacitor.getPlatform() === 'android') {
              this.listenSms()
            }
          })
          .finally(() => {
            this.$var('load', false)
          })
        break;
      default:
        $api.auth.sendCode(this.user.iin, this.user.phone).then((response) => {
          if (Capacitor.getPlatform() === 'android') {
            this.listenSms()
          }
        }).finally(() => {
          this.$var('load', false)
        })
        break;
      }

    },
    redirect() {
      this.$router.push({ name: 'mobile.index', })
    },
    close() {
      if (this.needCheck && this.code.length === 4) {
        this.$var('load', true)
        
        if (this.verificationType === 'otp') {
          $api.auth
            .checkCodeOtp(this.user.iin, { code: this.code, })
            .then((response) => {
              if (response.data.content.available) {
                this.hideButton = true 
                this.$emit('update-needCheck', false);  

                switch (this.user.company.payoutProvider) {
                case 'woopay':
                  this.setSuccessStatusData('showWoopay', 'woopay')
                  break;
                case 'bereke':
                  this.setSuccessStatusData('showBereke', 'bereke')
                  break;
                default:
                  break;
                }
              } else {
                this.$var('message', $app.secure.clean($n.t('3.9')))
                this.$var('load', false)
              }
            })
            .catch((response) => {
              console.log(response);
              this.$var('message', $app.secure.clean($n.t(response.response.data.message)))
              this.$var('load', false)
            })

          this.$var('load', false)
          
        } else {
          $api.users
            .checkCode($app.auth.user().id, { code: this.code, })
            .then((response) => {
              if (response.data.content.isAvailable) {
                this.$var('load', true)
                this.hideButton = true
                this.doTransaction()
              } else {
                this.$var('message', $app.secure.clean($n.t('3.9'))) // incorrect verify code message
                this.$var('load', false)
              }
            })
            .catch((response) => {
              console.log(response);
              this.$var('message', $app.secure.clean($n.t(response.response.data.message)))
              this.$var('load', false)
              
            })
        }
      }
    },

    doTransaction() {
      this.$var('load', true)
      this.hideButton = true

      const data = {
        cardId: this.cardIdentifier,
        amount: this.amount,
        pan: this.cardNumber ? this.cardNumber : this.mask,
        cardHolder: this.cardHolder,
        cardHash: this.toCardsId,
        mask: this.mask,
      }

      if (this.user.company.payoutProvider === 'woopay') {
        this.sendPaymentRequest('wooopayDoPay', 'woopay', 'showWoopaySuccess', data);
      } else if (this.user.company.payoutProvider === 'bereke') {
        this.sendPaymentRequest('berekeDoPay', 'bereke', 'showBerekeSuccess', data);
      }

      this.$var('load', true)
    },

    sendPaymentRequest(providerMethod, provider, successKey, data) {
      $api.payment[providerMethod](data).then((response) => {
        if (response.data.content) {
          console.log(response.data.content);
          
          if (Object.keys(response.data.content).length === 0) {
            this[successKey] = false;
            this.step = 1;
            alert($n.t('5.5.0'));
            this.$router.push({ name: 'mobile.index' });
          } else {
            this[successKey] = true;
            this[`${provider}Amount`] = response.data.content.amount;
            this[`${provider}Commission`] = response.data.content.userCommission;
            this[`${provider}DateTime`] = $app.date.format(response.data.content.dateTime, 'date');
            this[`${provider}OperationId`] = response.data.content.operationId;
            this.step = 1;
          }
        } else {
          this[successKey] = false;
          this.step = 1;
          alert('Ошибка проведения платежа');
          this.$router.push({ name: 'mobile.index' });
        }
      }).catch((ex) => {
        alert($n.t(ex.response.data.message));
        this.closeCode();
      }).finally(() => {
        this.$var('load', false);
      });
    },

    setSuccessStatusData( status, provider ) {
      this[`${status}Success`] = true
      this[`${provider}Amount`] = this.otpData.amount
      this[`${provider}Commission`] = this.otpData.commission
      this[`${provider}DateTime`] = $app.date.format(this.otpData.dateTime, 'date');
      this[`${provider}OperationId`] = this.otpData.operationId
    }
  },
}
</script>

<style lang="scss" scoped>
.c-phone-verify {
  z-index: 11;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ::v-deep {
    .c-go-back {
      position: absolute;
      top: calc(1px + env(safe-area-inset-top));
      left: 25px;
    }
  }

  .content {
    .text {
      h1 {
        font-size: 24px;
        color: #1c1c1c;
        font-weight: 500;
        text-align: center;
        margin: 0 0 5px 0;
      }

      p {
        margin: 0 auto 30px;
        text-align: center;
        width: 80%;
      }
    }

    .lower-text {
      p {
        margin: 0;
        font-size: 14px;
        text-align: center;

        &:last-child {
          color: #A91CD2;
          text-decoration: underline;
        }

        &:first-child {
          font-size: 12px;
          color: #9CA3AF;

          &.danger {
            color: red;
            font-size: 16px;
          }
        }
      }
    }

    .button-place {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;

      .user-default-button {
        width: 90%;
        display: block;
        margin: 0 auto;
      }
    }
  }

  body {
    background: #eff4fc;
  }

  .payment-success {
    display: flex;
    max-width: 500px;
    margin: 40px auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f5f5f5;

    .payment-title {
      display: flex;
      flex-direction: column;
      max-width: 80%;
      text-align: center;

      h2 {
        font-size: 28px;
        font-weight: 550;
        margin-bottom: 0;
      }

      .gray-text {
        color: #9B9B9B;
        font-size: 17px;
      }
    }

    .payment-content {
      display: flex;
      flex-direction: column;
      width: 95%;
      border-radius: 10px;
      box-shadow: 0 0 8px 0 #94949499;
      background-color: white;

      .line {
        margin: 5px 15px;
        border-top: 1px solid #EBEBEB;
      }

      .head-row {
        width: 100%;
        display: flex;
        justify-content: left;
        padding: 5px 16px;
      }

      .data-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 5px 16px;
      }
      .data-value {
        font-weight: bold;
      }
    }
    .close-payment-button {
      color: #912EDE;
      border: solid 2px #912EDE;
      border-radius: 10px;
      padding: 10px 42px;
      font-weight: 500;
      margin-top: 7%;
      background-color: #FFFFFF;
    }
  }
}
</style>
