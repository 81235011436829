/* eslint-disable quotes */
export default [
  {
    path: "/",
    component: () => import("layouts/App"),
    children: [
      {
        path: "",
        component: () => import("layouts/main/Index"),
        children: [
          {
            path: "",
            name: "login",
            icon: "genderless",
            component: () => import("pages/auth/Index"),
          },
          {
            path: "/recover",
            name: "recover",
            icon: "genderless",
            component: () => import("pages/auth/recover-password/Index"),
          },
          {
            path: "/recover/finish",
            name: "recover.finish",
            icon: "genderless",
            component: () => import("pages/auth/recover-password/Recover"),
          },
          {
            path: "/register",
            name: "register",
            icon: "genderless",
            component: () => import("pages/auth/registration/Index"),
          },
          {
            path: "/register/finish",
            name: "register.finish",
            icon: "genderless",
            component: () => import("pages/auth/registration/Finish"),
          },
          {
            path: "/privacy-policy",
            name: "privacyPolicy",
            icon: "genderless",
            component: () => import("pages/ agreements/privacy-policy/Index"),
          },
          {
            path: "/terms-of-use",
            name: "termsOfUse",
            icon: "genderless",
            component: () => import("pages/ agreements/terms-of-use/Index"),
          },
          {
            path: "/check/:uuid",
            name: "check.index",
            icon: "genderless",
            component: () => import("pages/check/Index"),
          },
          {
            path: "/complete/:uuid",
            name: "complete.index",
            icon: "genderless",
            component: () => import("pages/complete/Index"),
          },
        ],
      },
      {
        path: "desktop",
        component: () => import("layouts/admin/Index"),
        children: [
          {
            path: "main",
            name: "desktop.index",
            icon: "genderless",
            component: () => import("pages/desktop/index/Index"),
          },
          {
            path: "/desktop/user/:id",
            name: "desktop.user",
            icon: "genderless",
            component: () => import("pages/desktop/user/Index"),
          },
          {
            path: "/desktop/users",
            name: "desktop.users",
            icon: "genderless",
            component: () => import("pages/desktop/users/Index"),
          },
          {
            path: "/desktop/limits",
            name: "desktop.limits",
            icon: "genderless",
            component: () => import("pages/desktop/limits/Index"),
          },
          {
            path: "/desktop/group-limits",
            name: "desktop.groupLimits",
            icon: "genderless",
            component: () => import("pages/desktop/groupLimits/Index"),
          },
          {
            path: "/desktop/workdays",
            name: "desktop.workdays",
            icon: "genderless",
            component: () => import("pages/desktop/workdays/Index"),
          },
          {
            path: "/desktop/workday-visits",
            name: "desktop.workdayVisits",
            icon: "genderless",
            component: () => import("pages/desktop/workdayVisits/Index"),
          },
          {
            path: '/desktop/filials',
            name: 'desktop.filials',
            icon: 'genderless',
            component: () => import( 'pages/desktop/filials/Index' ),
          },
          {
            path: '/desktop/filial',
            name: 'desktop.filial',
            icon: 'genderless',
            component: () => import( 'pages/desktop/filial/Index' ),
          },
          {
            path: '/desktop/department/:id',
            name: 'desktop.department',
            icon: 'genderless',
            component: () => import( 'pages/desktop/department/Index' ),
          },
          {
            path: "/desktop/profile",
            name: "desktop.profile",
            icon: "genderless",
            component: () => import("pages/desktop/profile/Index"),
          },
          {
            path: "/desktop/support",
            name: "desktop.support",
            icon: "genderless",
            component: () => import("pages/desktop/support/Index"),
          },
          {
            path: "/desktop/eds",
            name: "desktop.eds",
            icon: "genderless",
            component: () => import("pages/desktop/eds/Index"),
          },
          {
            path: '/desktop/integration',
            name: 'desktop.integration',
            icon: 'genderless',
            component: () => import('pages/desktop/integration/Index'),
          },
        ],
      },
      {
        path: "mobile",
        component: () => import("layouts/user/Index"),
        children: [
          {
            path: "transactions",
            name: "mobile.transactions",
            left: "",
            right: "mobile.index",
            icon: "genderless",
            component: () => import("pages/mobile/transactions/Index"),
          },
          {
            path: "main",
            name: "mobile.index",
            left: "mobile.transactions",
            right: "mobile.profile",
            icon: "genderless",
            component: () => import("pages/mobile/index/Index"),
          },
          {
            path: "/mobile/agreement-document",
            name: "mobile.agreement-document",
            icon: "genderless",
            component: () => import('pages/mobile/agreement-document/Index'),
          },
          {
            path: "/mobile/civil-legal-document",
            name: "mobile.civil-legal-document",
            icon: "genderless",
            component: () => import('pages/mobile/civil-legal-document/Index'),
          },
          {
            path: "/mobile/completion-certificate",
            name: "mobile.completion-certificate",
            icon: "genderless",
            component: () => import('pages/mobile/completion-certificate/Index'),
          },
          {
            path: "profile",
            name: "mobile.profile",
            left: "mobile.index",
            right: "mobile.timeTracker",
            icon: "genderless",
            component: () => import("pages/mobile/profile/Index"),
          },
          {
            path: "profile/my-data",
            name: "mobile.profile.myData",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/my-data/Index"),
          },
          {
            path: "profile/my-documents",
            name: "mobile.profile.myDocuments",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/my-documents/Index"),
          },
          {
            path: "profile/my-cards",
            name: "mobile.profile.myCards",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/my-cards/Index"),
          },
          {
            path: "payment/after",
            name: "mobile.payment.after",
            left: "mobile.profile",
            right: "mobile.index",
            icon: "genderless",
            component: () => import("pages/mobile/after-pay/Index"),
          },
          {
            path: "profile/support",
            name: "mobile.profile.support",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/support/Index"),
          },
          {
            path: "profile/security",
            name: "mobile.profile.security",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/security/Index"),
          },
          {
            path: "/mobile/profile/support/faqs",
            name: "mobile.profile.support.faqs",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/faqs/Index"),
          },
          {
            path: "/mobile/profile/notifications",
            name: "mobile.profile.notifications",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/notifications/Index"),
          },
          {
            path: "/mobile/profile/contacts",
            name: "mobile.profile.contacts",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/contacts/Index"),
          },
          {
            path: "/mobile/profile/support/applications",
            name: "mobile.profile.support.applications",
            icon: "genderless",
            component: () => import("pages/mobile/applications/Index"),
          },
          // time-tracker
          {
            path: "/mobile/time-tracker",
            name: "mobile.timeTracker",
            left: "mobile.profile",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/time-tracker/Index"),
          },
          {
            path: "/mobile/time-tracker/visits",
            name: "mobile.timeTracker.visits",
            left: "mobile.timeTracker",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/visits/Index"),
          },
          {
            path: "/mobile/time-tracker/visit-confirmations",
            name: "mobile.timeTracker.visitConfirmations",
            left: "mobile.timeTracker",
            right: "",
            icon: "genderless",
            component: () => import("pages/mobile/visit-confirmations/Index"),
          },
        ],
      },
      {
        path: "",
        component: () => import("layouts/payment/Index"),
        children: [
          {
            path: "/mobile/payment",
            name: "mobile.payment",
            icon: "genderless",
            component: () => import("pages/mobile/payment/Index"),
          },
        ],
      },
    ],
  },
]
